import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { NavLink } from "react-router-dom";

class WhatIsMadchef extends Component {
  state = {};
  render() {
    return (
      <Container fluid={"true"} className="what-is-madchef">
        <Row>
          <Col lg={6} md={6}>
            <h1>How it Works</h1>
            <img style={{ width: "100%" }} src="https://madchefcateringservices.com/imgur-images/NzRdr0v.png" alt="steps"></img>
            <p>
              Click on 'Create Menu' and follow these simple steps to design your own customized menu. Its THAT simple!
              <br/>
              For additional services do also checkout 'MCS 360.'
            </p>
            <div className="two-button">
              <NavLink className="nav-link" to="/createMenu">
                Create Menu
            </NavLink>
              <NavLink className="nav-link" to={"/mcs360"}>
                MCS 360
              </NavLink>
            </div>
          </Col>

          <Col lg={6} md={6}>
            <img
              className="what-is-madchef-img"
              src="https://madchefcateringservices.com/imgur-images/zCDYfyq.jpg"
              alt="whatismadchef"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default WhatIsMadchef;
