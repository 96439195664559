import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import OrangeBar from "./Master/OrangeBar";
import CorporateForm from "./Corporate/CorporateForm";
//import CorporateCard from "./MCS360/MCSCard";
import Header from "./Master/MenuBar";
import Call from "./Master/Call";
//import Header from "./Master/MenuBar";
class CorporateLunch extends Component {
  state = { scrolled: false };
  
  componentDidMount() {
    let that=this;
    window.scrollTo(0, 0);
    window.onscroll = function() {
      if(window.pageYOffset > 0) {
        that.setState({scrolled: true});
      }
      else{
        that.setState({scrolled: false});
      }
    };
  };

  render() {
    return (
      <div>
        <Header scrolled={this.state.scrolled} />
        <Call />
        <OrangeBar pageName="Fresh and Delicious, Delivered! " />
        <container fluid={true} >
        <Container className="corporate-bg">
           <img
            style={{ width: "100%", paddingTop: "20px" }}
            alt="corporate"
            src="https://madchefcateringservices.com/imgur-images/AuwerzP.png"
          />
        </Container>
        </container>
        <Container style={{ marginTop: "30px" }}>
          <CorporateForm />
        </Container>
      </div>
    );
  }
}

export default CorporateLunch;
