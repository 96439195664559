import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Navbar, NavDropdown } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import { NavLink } from "react-router-dom";
import NavbarBrand from "react-bootstrap/NavbarBrand";

class MenuBar extends Component {
  state = {};
  render() {
    return (
      <Container className="header" fluid={true}>
        <Row>
          <Col className="social-bar-left">
            <span style={{ padingRight: "5px" }}> Follow Us: </span>

            <a
              style={{ color: "white" }}
              href="https://fb.com/madchefcateringservices"
              target="_blank"
              rel="noreferrer noopener"
              className="fa fab fa-facebook-square icons"
            >
              {}
            </a>

            <a
              style={{ color: "white" }}
              href="https://www.instagram.com/madchefcateringservices/"
              target="_blank"
              rel="noreferrer noopener"
              className="fa fab fa-instagram icons"
            >
              {}
            </a>
            <a
              style={{ color: "white" }}
              href="https://m.me/madchefcateringservices"
              target="_blank"
              rel="noreferrer noopener"
              className="fa fas fa-comments icons"
            >
              {}
            </a>
          </Col>
          <Col className="social-bar-right">
            <i className="fa fas fa-envelope icons"></i>
            <span style={{ paddingRight: "15px" }}>
              {" "}
              contact@madchefcateringsevices.com
            </span>
            <i className="fa fas fa-phone-square icons"></i>
            <span> +880-1856-585694</span>
          </Col>
        </Row>
        <Navbar
          variant="dark"
          className={this.props.scrolled ? "menu-bar-scrolled" : "menu-bar"}
          expand="lg"
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="our-nav">
            <Nav>
              <NavLink className="nav-link" to={"/"}>
                Home
              </NavLink>

              <NavDropdown title="Create Menu" id="basic-nav-dropdown">
                <NavLink className="nav-link" to="/createMenu">
                  Traditional
                </NavLink>

                <NavLink className="nav-link" to="/bbqMenu">
                  BBQ Menu
                </NavLink>
              </NavDropdown>

              <NavLink className="nav-link" to="/packages">
                Packages
              </NavLink>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse id="basic-navbar-nav" className="our-nav">
            <Nav className="nav-right">
              <NavLink className="nav-link" to={"/mcs360"}>
                MCS 360
              </NavLink>
              <NavLink className="nav-link" to={"/corporateLunch"}>
                Corporate
              </NavLink>
              <NavLink className="nav-link" to={"/contact"}>
                Contact Us
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <NavbarBrand>
          <Image
            className={this.props.scrolled ? "brand-img-scrolled" : "brand-img"}
            src={"https://madchefcateringservices.com/imgur-images/K7LlCa8.png"}
            roundedCircle
          ></Image>
        </NavbarBrand>
      </Container>
    );
  }
}

export default MenuBar;
