import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Container from "react-bootstrap/Container";
import Carbs from "../CreateMenu/Carbs";
import Chicken from "../CreateMenu/Chicken";
import Beef from "../CreateMenu/Beef";
import Mutton from "../CreateMenu/Mutton";
import Fish from "../CreateMenu/Fish";
import OnTheSide from "../CreateMenu/OnTheSide";

class MenuTabs extends Component {
  state = {};

  render() {
    return (
      <Container>
        <Tabs className="tab-style">
          <TabList>
            <Tab>CARBS</Tab>
            <Tab>CHICKEN</Tab>
            <Tab>BEEF</Tab>
            <Tab>MUTTON</Tab>
            <Tab>FISH</Tab>
            <Tab>ON THE SIDE</Tab>
          </TabList>

          <TabPanel>
            <Carbs
              currentMenu={this.props.currentMenu}
              onAddItem={this.props.onAddItem}
            />
          </TabPanel>
          <TabPanel>
            <Chicken
              currentMenu={this.props.currentMenu}
              onAddItem={this.props.onAddItem}
            />
          </TabPanel>
          <TabPanel>
            <Beef
              currentMenu={this.props.currentMenu}
              onAddItem={this.props.onAddItem}
            />
          </TabPanel>
          <TabPanel>
            <Mutton
              currentMenu={this.props.currentMenu}
              onAddItem={this.props.onAddItem}
            />
          </TabPanel>
          <TabPanel>
            <Fish
              currentMenu={this.props.currentMenu}
              onAddItem={this.props.onAddItem}
            />
          </TabPanel>
          <TabPanel>
            <OnTheSide
              currentMenu={this.props.currentMenu}
              onAddItem={this.props.onAddItem}
            />
          </TabPanel>
        </Tabs>
      </Container>
    );
  }
}

export default MenuTabs;
