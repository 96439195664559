import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//import CustomerCardGrid from "./Components/Home/customerCardGrid";
//import ServiceCardGrid from "./Components/Home/serviceCardGrid";
//import Awards from "./Components/Home/awards";
//import FoodCard from "./Components/Home/foodCard";
//import ContactUsSection from "./Components/Home/contactUsSection";
import Footer from "./Components/Master/Footer";

//import GetInTouch from "./Components/Contact/GetInTouch";
//import ContactInfo from "./Components/Contact/ContactInfo";
//import Header from "./Components/Master/MenuBar";
import ContactUs from "./Components/ContactUs";
//import FoodCardCarosel from "./Components/Home/FoodCardCarosel";
import CreateMenu from "./Components/CreateMenu";
import BBQMenu from "./Components/BBQMenu";
import MCS360 from "./Components/MCS360";
import Home from "./Components/Home";
import CorporateLunch from "./Components/CorporateLunch";
import Packages from "./Components/Packages"

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/createMenu" component={CreateMenu} />
        <Route path="/corporateLunch" component={CorporateLunch} />
        <Route path="/contact" component={ContactUs} />
        <Route path="/packages" component={Packages} />
        <Route path="/mcs360" component={MCS360} />
        <Route path="/bbqMenu" component={BBQMenu} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
