import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import FoodItem from "./FoodItem";

class Beef extends Component {
  state = {};
  render() {
    var foods = [
      {
        id: "1",
        name: "Achari Gosht (Spicy)",
        details:
          "Beef with perfectly blended spicy and tanginess of deshi achar.",
        image: "https://madchefcateringservices.com/imgur-images/1JwKJZd.jpg",
        price:240
      },
      {
        id: "2",
        name: "Mezbaan Gosht",
        details: "Beef rich in spices cooked in Mezbani style.",
        image: "https://madchefcateringservices.com/imgur-images/MDH5aFf.jpg",
        price:250
      },
      {
        id: "3",
        name: "Beef (Aloo) Bhuna",
        details:
          "Roasted potatoes to go with your chunks of meat, you know you want it!",
        image: "https://madchefcateringservices.com/imgur-images/TAGc0zR.jpg",
        price:220
      },
      {
        id: "4",
        name: "Beef Kala Bhuna",
        details:
          "Mouth watering, succulent meat to compliment your hot plate of rice!",
        image: "https://madchefcateringservices.com/imgur-images/pUTw0US.jpg",
        price:200
      },
      {
        id: "5",
        name: "Kata Moshla Beef (Spicy)",
        details:
          "Soft and, tender beef in a thick spicy gravy with a hint of lime.",
        image: "https://madchefcateringservices.com/imgur-images/bXCJS1a.jpg",
        price:230
      },
      {
        id: "6",
        name: "Beef Hari Kabab",
        details:
          "A little yogurt and with a little green chili, this kabab is what you would want daily.",
        image: "https://madchefcateringservices.com/imgur-images/cMphIIZ.jpg",
        price:300
      },
      {
        id: "7",
        name: "Beef Kofta Curry",
        details: "Tender meatballs in a simmering in spice is just nice!",
        image: "https://madchefcateringservices.com/imgur-images/0nzcujq.jpg",
        price:280
      },
      {
        id: "8",
        name: "Beef Kolija Bhuna",
        details: "Humble pieces of beef liver cooked in flavorful spices. ",
        image: "https://madchefcateringservices.com/imgur-images/vRyCNYQ.jpg",
        price:210
      }
    ];
    return (
      <Row>
        {foods.map(food => (
          <Col md="6" lg="4" sm="6" xs="12" key={food.name}>
            <FoodItem
              currentMenu={this.props.currentMenu}
              onAddItem={this.props.onAddItem}
              key={food.id}
              id={food.id}
              food={food}
            />
          </Col>
        ))}
      </Row>
    );
  }
}

export default Beef;
