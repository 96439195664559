import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

class GetInTouch extends Component {
  state = {
    fromPage: "contact",
    name: "",
    mail: "",
    comment: ""
  };
  handleName = event => {
    this.setState({ name: event.target.value });
  };
  handleEmail = event => {
    this.setState({ mail: event.target.value });
  };
  handleComment = event => {
    this.setState({ comment: event.target.value });
  };
  handleClose = () => {
    this.setState({ show: false });
    //window.location.reload();
  };
  handleSubmit = event => {
    event.preventDefault();
    axios
      .post("https://madchefcateringservices.com/api/main.js", this.state)
      .then(res => {
        //console.log(res);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
    console.log(this.state);
    this.setState({ show: true });
  };
  render() {
    return (
      <Form
        style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
        onSubmit={this.handleSubmit}
      >
        <h5 className="footer-header">Get In Touch</h5>
        <p style={{ fontSize: "1.2rem" }}>
          If you have any query please call us or fill up the form below. We
          will get back to you soon.
        </p>
        <Form.Group>
          <Form.Control
            type="text"
            style={{ border: "1.5px solid #471435" }}
            placeholder="Name *"
            onChange={this.handleName}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            style={{ border: "1.5px solid #471435" }}
            type="email"
            placeholder="E-mail Address *"
            onChange={this.handleEmail}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            style={{ border: "1.5px solid #471435" }}
            as="textarea"
            rows="4"
            placeholder="Comment *"
            onChange={this.handleComment}
            required
          />
        </Form.Group>
        <Button className="corporate-button" type="submit">
          Submit
        </Button>
        <Modal
          style={{ top: "300px", border: "0" }}
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton style={{ backgroundColor: "#f69d36" }}>
            <Modal.Title style={{ color: "#471435" }}>
              Congratulations !! You've Succesfully Placed a Comment. We will
              get back to you soon.
            </Modal.Title>
          </Modal.Header>
        </Modal>
      </Form>
    );
  }
}

export default GetInTouch;
