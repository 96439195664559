import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MCSCard from "./MCS360/MCSCard";
import OrangeBar from "./Master/OrangeBar";
import Header from "./Master/MenuBar";
import Call from "./Master/Call";
class MCS360 extends Component {
  state = { scrolled: false };

  componentDidMount() {
    let that = this;
    window.scrollTo(0, 0);
    window.onscroll = function () {
      if (window.pageYOffset > 0) {
        that.setState({ scrolled: true });
      }
      else {
        that.setState({ scrolled: false });
      }
    };
  };

  render() {
    return (
      <div>
        <Header scrolled={this.state.scrolled} />
        <Call />
        <OrangeBar pageName=" The 360° satisfaction" />
        <Container style={{ marginTop: "2rem" }}>
          <p style={{textAlign:"center",marginBottom:"2rem"}}>
            We have a list of suggested vendors related to your events whom you may also work with. Don’t worry.
             They are certified, registered and experienced in providing
             excellent services. Best part? They work well together with us too.
             Check them out! You may contact them directly, or ask us to manage
             it for you in addition to your catering needs.
          </p>
          <Row>
            <Col xs="12" sm="12" md="12" lg="6">
              <MCSCard
                img="https://madchefcateringservices.com/imgur-images/UKD65sy.jpg"
                title="Designer Cakes "
                subTitle="Cake O Clock, Brownie Hut"
                details="Your unique event deserves a special cake too! Find deliciously custom bakes for any occasion." />
            </Col>

            <Col xs="12" sm="12" md="12" lg="6">
              <MCSCard img="https://madchefcateringservices.com/imgur-images/7v2XR3J.jpg"
                title="Live Barbecue"
                subTitle=" Madchef Catering Services "
                details="Sit back and enjoy your event while culinary experts grill your favorite meats and barbecues. " />
            </Col>

            <Col xs="12" sm="12" md="12" lg="6">
              <MCSCard img="https://madchefcateringservices.com/imgur-images/8NGl81X.jpg"
                title="Waiter Service"
                subTitle="Madchef Catering Services"
                details="Find people with passion and product knowledge who cares about your guests and their experience." />
            </Col>

            <Col xs="12" sm="12" md="12" lg="6">
              <MCSCard img="https://madchefcateringservices.com/imgur-images/kqLqJPB.jpg"
                title=" Supplimentary"
                subTitle="Fuchka, Hawai Mithai, Jilapi, Paan, Coffee"
                details="Find tried and tested suppliers of local delicacies at your event which everyone will enjoy." />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default MCS360;
