import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FoodCard from "./foodCard";
class FoodCardCarosel extends Component {
  state = {};
  render() {
    const settings = {
      dots: true,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 830,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ]
    };

    return (
      <div>
        <Slider {...settings}>
          <div>
            <FoodCard
              image="https://madchefcateringservices.com/imgur-images/x01E2fy.jpg"
              title="Achari Laetka Khichuri"
              details=""
            />
          </div>
          <div>
            <FoodCard
              image="https://madchefcateringservices.com/imgur-images/wct4Uvb.jpg"
              title="Boneless Chicken Handi"
              details=""
            />
          </div>
          <div>
            <FoodCard
              image="https://madchefcateringservices.com/imgur-images/pUTw0US.jpg"
              title="Beef Kala Bhuna"
              details=""
            />
          </div>
          <div>
            <FoodCard
              image="https://madchefcateringservices.com/imgur-images/F1KfDbc.jpg"
              title="Rara Mutton and Keema Curry"
              details=""
            />
          </div>
          <div>
            <FoodCard
              image="https://madchefcateringservices.com/imgur-images/eIVbYew.jpg"
              title="Rui Kofta curry"
              details=""
            />
          </div>
        </Slider>
      </div>
    );
  }
}

export default FoodCardCarosel;
