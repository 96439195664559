import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

class MenuForm extends Component {
  state = {
    fromPage: "createmenu",
    name: "",
    mail: "",
    phone: "",
    comment: "",
    menuList: "",
    number0fPeople: 1,
    show: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.menu !== this.props.menu) {
      this.setState({ menuList: nextProps.menuString });
    }
  }

  handleName = event => {
    //console.log(menuString);
    this.setState({ name: event.target.value });
  };
  handlePhone = event => {
    this.setState({ phone: event.target.value });
  };
  handleEmail = event => {
    this.setState({ mail: event.target.value });
  };
  handleComment = event => {
    this.setState({ comment: event.target.value });
  };
  handleNumberOfpeople = event => {
    this.setState({ number0fPeople: event.target.value })
  }
  handleClose = () => {
    this.setState({ show: false });
    window.location.reload();
  };

  handleSubmit = event => {
    event.preventDefault();
    axios
      .post("https://madchefcateringservices.com/api/main.js", this.state)
      .then(res => {
        //console.log(res);
        this.setState({ show: true });
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    var selectedItems = this.props.menu;
    //console.log(this.state.menuList);
    return (
      <Container>
        <Row className="create-menu-form">
          <Col lg="7" md="12" xs="12" sm="12" style={{ marginBottom: "1rem" }}>
            <h1 className="create-menu-form-header">Your Menu and Subtotal: {this.props.totalPrice} tk</h1>
            <div className="selected-item-body">
              <Row>
                {selectedItems.map(item => (
                  <Col md="6" key={item.name} style={{ margin: "0" }}>
                    <div className="selected-menu-item" key={item.name} id={item.name}>
                      {item.name} : {item.price} tk
                      <button
                        onClick={() => this.props.onCancelClick(selectedItems.indexOf(item))}
                        className="fa fas fa-times create-menu-cross"
                      ></button>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
              <p className="total-price">{"Total for " + this.state.number0fPeople + " People : " + this.props.totalPrice * this.state.number0fPeople + " tk"}
              </p>
          </Col>

          <Col lg="5" md="12" xs="12" sm="12">
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="formBasicText">
                <Form.Control
                  type="text"
                  style={{ border: "1.5px solid #471435" }}
                  placeholder="Name *"
                  onChange={this.handleName}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicText">
                <Form.Control
                  type="number"
                  style={{ border: "1.5px solid #471435" }}
                  placeholder="Phone Number *"
                  onChange={this.handlePhone}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  style={{ border: "1.5px solid #471435" }}
                  type="email"
                  placeholder="E-mail Address *"
                  onChange={this.handleEmail}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  style={{ border: "1.5px solid #471435" }}
                  type="number"
                  placeholder="Number of people*"
                  onChange={this.handleNumberOfpeople}
                  required
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Control
                  style={{ border: "1.5px solid #471435" }}
                  as="textarea"
                  rows="4"
                  placeholder="Comment "
                  onChange={this.handleComment}
                />
              </Form.Group>
              <button className="create-menu-button" type="submit">
                Submit
              </button>
              <Modal
                style={{ top: "300px", border: "0" }}
                show={this.state.show}
                onHide={this.handleClose}
              >
                <Modal.Header
                  closeButton
                  style={{ backgroundColor: "#f69d36" }}
                >
                  <Modal.Title style={{ color: "#471435" }}>
                    Congratulations !! You've Succesfully Placed a Menu Request.
                    We will get back to you soon.
                  </Modal.Title>
                </Modal.Header>
              </Modal>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MenuForm;
