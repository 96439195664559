import React, { Component } from "react";
//import Jumbotron from "react-bootstrap/Jumbotron";
//import Button from "react-bootstrap/Button";
//import heroImage from "../../images/hero.png";
//var heroStyle = {
//backgroundImage: `url(${heroImage})`
//};
class Hero extends Component {
  state = {};
  render() {
    return (
      <section class="bgimage">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h1>What is Madchef Catering Services ?</h1>
              <p>
                What creates the true traditional experience of Dhaka is not just
              a mix of spices and cooking techniques behind every dish. There’s
              a certain energy which defines our cultural food experience; a
              unique manner in which each flavor component connects with the other
              to create a harmony not only in our mouths, but in the deepest
              pits of our souls as well. It drives us mad for it! This strange
              and undefined happiness would be selfish to not share with you. So
              here we are.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;
