import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import FoodItem from "../CreateMenu/FoodItem";

class OnTheSide extends Component {
  state = {};
  render() {
    var foods = [
      {
        id: "1",
        name: "Mixed deshi vegetables",
        details:
          "Bite-sized vegetables in tasty and spicy gravy!",
        image: "https://madchefcateringservices.com/imgur-images/8QRL6Rc.jpg",
        price:60
      },
      {
        id: "2",
        name: "Boiled egg masala",
        details: "Hard-boiled egg roasted and dunked in spicy gravy.",
        image: "https://madchefcateringservices.com/imgur-images/ytfGvxu.jpg",
        price:65
      },
      {
        id: "3",
        name: "Shahi Mattar Paneer",
        details:
          "Chunks of cottage cheese marinated and slowly cooked in rich gravy with peas.",
        image: "https://madchefcateringservices.com/imgur-images/HMRmUwW.jpg",
        price:90
      },
      {
        id: "4",
        name: "Potato/Egg chop",
        details:
          "You want to bite into that golden ball and, be surprised with the egg inside!",
        image: "https://madchefcateringservices.com/imgur-images/2kbQNEO.jpg",
        price:45
      },
      {
        id: "5",
        name: "Ghono Daal",
        details: "Thick spicy stew of lentils to go with your rice bowl.",
        image: "https://madchefcateringservices.com/imgur-images/pWC2W97.jpg",
        price:45
      },
      {
        id: "6",
        name: "Booter Daal (Gila-kolija)",
        details:
          "Creamy and, buttery dal that comes with pieces of chicken lever!",
        image: "https://madchefcateringservices.com/imgur-images/pELgjuh.jpg",
        price:70
      },
      {
        id: "7",
        name: "Shorisha Keema Chop",
        details:
          "Mustardy minced meat stuffed into soft and golden potato balls.",
        image: "https://madchefcateringservices.com/imgur-images/pbHSAQG.jpg",
        price:130
      },
      {
        id: "8",
        name: "Shahi Mutton Tundey Kabab",
        details:
          "Straight from the Awadhi cuisine with a blend of 160 spices, this kebab comes with a hint of yogurt and mint!",
        image: "https://madchefcateringservices.com/imgur-images/8GjcIrR.jpg",
        price:225
      }
    ];
    return (
      <Row>
        {foods.map(food => (
          <Col md="6" lg="4" sm="6" xs="12" key={food.name}>
            <FoodItem
              currentMenu={this.props.currentMenu}
              onAddItem={this.props.onAddItem}
              key={food.id}
              id={food.id}
              food={food}
            />
          </Col>
        ))}
      </Row>
    );
  }
}

export default OnTheSide;
