import React, { Component } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class Cart extends Component {
  state={cartShow:false};

  onCartClick = () => {
    this.setState({ cartShow: true });
  }

  onCartExitClick=()=>{
    this.setState({ cartShow: false });
  }

  render() {
    let selectedItems = this.props.menu;
    return (
      <div>
        {
          this.state.cartShow ?
            <div className="cart">
              <h4>Cart</h4>
              <div className="cart-body">
                {this.props.menu.map(item => (
                  <Row key={item.id} className="item-row">
                    <Col xs={3} sm={3} md={3} lg={3}>
                      <img className="cart-img" key={item.name} alt={item.src} src={item.image}></img>
                    </Col>

                    <Col xs={7} sm={7} md={7} lg={7} style={{ marginLeft: "-10px" }}>
                      <p key={item.name}>{item.name}</p>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} style={{ marginLeft: "0px" }}>
                      <p key={item.name}>{item.price + "tk"}</p>
                      <span onClick={() => this.props.handleCancelClick(selectedItems.indexOf(item))} className="fav fa fa-trash delete" aria-hidden="true"></span>
                    </Col>
                  </Row>
                ))}
              </div>

              <Row>
                <Col xs={9} sm={9} md={9} lg={9}>
                  {this.props.totalPrice>0?<button onClick={this.props.onCheckout} className="cart-button-checkout">{"Checkout - " + this.props.totalPrice + " tk"}</button>:null}
                </Col>

                <Col xs={3} sm={3} md={3} lg={3}>
                  <button onClick={this.onCartExitClick} className="cart-button-exit"><span>&#10006;</span></button>
                </Col>
              </Row>
            </div>
            :
            <div className="cart-icon">
              <span onClick={this.onCartClick} className="fav fa fa-cutlery cart-menu" aria-hidden="true"></span>
               {this.props.menu.length>0?<p className="cart-item-count">{this.props.menu.length}</p>:null}
            </div>
        }
      </div>
    );
  }
}

export default Cart;