import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
class Footer extends Component {
  state = {};
  render() {
    return (
      <Container fluid={true} className="footer-style">
        <Row>
          <Col>
            <h5 className="footer-header">Stay Connected</h5>
            <div className="footer-social">
              <a
                href="https://fb.com/madchefcateringservices"
                target="_blank"
                rel="noreferrer noopener"
                className="fa fab fa-facebook-square icons"
              >
                {}
              </a>

              <a
                href="https://www.instagram.com/madchefcateringservices/"
                target="_blank"
                rel="noreferrer noopener"
                className="fa fab fa-instagram icons"
              >
                {}
              </a>
              <a
                href="https://m.me/madchefcateringservices"
                target="_blank"
                rel="noreferrer noopener"
                className="fa fas fa-comments icons"
              >
                {}
              </a>
            </div>
          </Col>
          <Col>
            <h5 className="footer-header">Contact Us</h5>
            <div className="footer-item">
              <li className=" fa fas fa-phone-square" />
              {"  "}+880-1856-585694{" "}
            </div>

            <div className="footer-item">
              <li className=" fa far fa-envelope" />
              {"  "}contact@madchefcateringsevices.com
            </div>

            <div className="download-div">
              <a
                className="download-menu"
                href="https://drive.google.com/u/0/uc?id=1hGZrWWAqATFjy8BYyEP9tc6sEU1xu7fK&export=download"
                target="_blank"
                rel="noreferrer noopener"
              >
                Download Menu
            </a>
            </div>

          </Col>

          <Col md="4" lg="4">
            <h5 className="footer-header">Our Ventures</h5>
            <Row className="venture">
              <a
                href="https://facebook.com/madchefdhk/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  className="footer-img"
                  src={"https://madchefcateringservices.com/imgur-images/ucTvZK4.png"}
                  alt="logo"
                ></img>
              </a>

              <a
                href="https://facebook.com/cheezbd/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  className="footer-img"
                  src={"https://madchefcateringservices.com/imgur-images/iikH6d8.jpg"}
                  alt="logo"
                ></img>
              </a>
            </Row>
          </Col>
        </Row>

        <hr
          style={{
            marginTop: "1",
            backgroundColor: "#f69d36",
            marginBottom: "5px"
          }}
        />
        <p
          style={{
            paddingBottom: "10px",
            marginBottom: "-7px",
            fontSize: "1rem",
            fontWeight: "350"
          }}
        >
          © Madchef Catering Services {new Date().getFullYear()}. All Right
          Reserved. Designed and Developed by{" "}
          <a
            style={{ color: "#f69d36" }}
            href="https://headless.ltd"
            target="_blank"
            rel="noreferrer noopener"
          >
            Headless Technologies
          </a>
          .
        </p>
      </Container>
    );
  }
}

export default Footer;
