import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import FoodItem from "../CreateMenu/FoodItem";

class Carbs extends Component {
  state = {};
  render() {
    var foods = [
      {
        id: "1",
        name: "Plain peas polao",
        details: "Aromatic rice with green peas and, a hint of ghee.",
        image: "https://madchefcateringservices.com/imgur-images/3iG3w8D.jpg",
        price:80
      },
      {
        id: "3",
        name: "Morog polao",
        details:
          "Tender pieces of chicken in polao, garnished with crunchy fried onions. ",
        image: "https://madchefcateringservices.com/imgur-images/YNkFkly.jpg",
        price:220
      },
      {
        id: "4",
        name: "Bhuna Khichuri (Chicken/ Beef)",
        details: "Aromatic Yellow Rice with hidden juicy meat pieces that are worth finding!",
        image: "https://madchefcateringservices.com/imgur-images/grzmgAs.jpg",
        price:210
      },
      {
        id: "6",
        name: "Achari Laetka Khichuri",
        details: "Yellow soft rice that melts in your mouth",
        image: "https://madchefcateringservices.com/imgur-images/x01E2fy.jpg",
        price:220
      },
      {
        id: "7",
        name: "Kachhi Biriyani",
        details:
          "Richly spiced mutton in all that rice with the winner roasted potatoes.",
        image: "https://madchefcateringservices.com/imgur-images/jU8Txjl.jpg",
        price:330
      },
      {
        id: "8",
        name: "Beef/Chicken Tehari",
        details:
          "Flavorful cardamom rice with different spices mixed with chunk of meats",
        image: "https://madchefcateringservices.com/imgur-images/NkeEFRr.jpg",
        price:240
      },
      {
        id: "9",
        name: "Paratha",
        details: "Golden flat breads, puffy and soft best for health",
        image: "https://madchefcateringservices.com/imgur-images/zHtXCyM.jpg",
        price:25
      },
      {
        id: "10",
        name: "Chaal-er Ruti",
        details: "Quality Rice flat breads, soft and tasty, made with care",
        image: "https://madchefcateringservices.com/imgur-images/TWTRzkE.jpg",
        price:20
      },
      {
        id: "11",
        name: "Luchi",
        details:
          "Little moon of a flat bread, ready to accompany its meaty friends.",
        image: "https://madchefcateringservices.com/imgur-images/YbTY6vf.jpg",
        price:25
      },
      {
        id: "2",
        name: "Plain rice",
        details:
          " Simple Steamed white rice, nice and clean with great quality ",
        image: "https://madchefcateringservices.com/imgur-images/3KZ6MVP.jpg",
        price:50
      }
    ];
    return (
      <Row>
        {foods.map(food => (
          <Col md="6" lg="4" sm="6" xs="12" key={food.name}>
            <FoodItem
              currentMenu={this.props.currentMenu}
              onAddItem={this.props.onAddItem}
              key={food.id}
              id={food.id}
              food={food}
            />
          </Col>
        ))}
      </Row>
    );
  }
}

export default Carbs;
