import React, { Component } from "react";
import CardDemo from "./customerCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

class CustomerCardGrid extends Component {
  state = {};
  render() {
    return (
      <Container style={{ paddingTop: "3rem", paddingBottom: "1rem" }}>
        <Card
          style={{
            borderStyle: "none",
            backgroundColor: "transparent"
          }}
        >
          <Card.Text
            style={{
              textAlign: "center"
            }}
          >
            <h2 style={{ color: "#471435" }}>
              <i class="fa fas fa-quote-left"></i>
            </h2>
            <h3 style={{ color: "#471435" }}>Happy Customers</h3>
          </Card.Text>
        </Card>
        <Row>
          <Col xl="3" lg="3" md="6" sm="6" xs="6">
            <CardDemo
              name="Reshad Mohaimen"
              designation="RTM Implementation Manager"
              company="British American Tobacco Bangladesh"
              quote="The service provided was right on time, they set it all up for me, and picked it up right away! Thank you MCS for taking the stress out of cooking."
              imgSrc="https://madchefcateringservices.com/imgur-images/CrPZ8bx.jpg"
            />
          </Col>
          <Col xl="3" lg="3" md="6" sm="6" xs="6">
            <CardDemo
              name="Humayra Chinu"
              quote="The food was fabulous!  Everyone commented on this, so it was not just my opinion. 
              The quality was superb, the presentation was very nice, and it was delivered on time, as promised."
              designation="Branch Manager"
              company="NRB Global Bank"
              imgSrc="https://madchefcateringservices.com/imgur-images/d0c6NMk.jpg"
            />
          </Col>
          <Col xl="3" lg="3" md="6" sm="6" xs="6">
            <CardDemo
              name="Raihan Amin Khan"
              quote="You guys are the best!  I’m not sure which is better – your service or the food!"
              imgSrc="https://madchefcateringservices.com/imgur-images/0FSGhbb.jpg"
              designation="Director"
              company="Total Holdings Limited"
            />
          </Col>
          <Col xl="3" lg="3" md="6" sm="6" xs="6">
            <CardDemo
              name="Chowdhury Tanzim Karim"
              quote="I wish you well and encourage you to continue serving us with healthy beautifully prepared catering as you catered for us."
              imgSrc="https://madchefcateringservices.com/imgur-images/yNLFnHV.jpg"
              designation="Lawyer"
              company="C.T. Karim & Partners"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CustomerCardGrid;
