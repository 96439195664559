import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import FoodItem from "../CreateMenu/FoodItem";

class Chicken extends Component {
  state = {};
  render() {
    var foods = [
      {
        id: "1",
        name: "Biye-bari Chicken Roast (desi)",
        details:
          "Dunked in spices of a thousand wishes, this chicken roast is deshi and gourmet.",
        image: "https://madchefcateringservices.com/imgur-images/mjP1iwZ.jpg",
        price:140
      },
      {
        id: "2",
        name: "Tandoori Chicken",
        details:
          "Chicken marinated in yogurt and seasoned with special spices; grilled in tandoori oven",
        image: "https://madchefcateringservices.com/imgur-images/KsI7jf0.jpg",
        price:140
      },
      {
        id: "3",
        name: "Achari Chicken",
        details: "A meaty memory-trip of grandma’s pickle!",
        image: "https://madchefcateringservices.com/imgur-images/glYntOx.jpg",
        price:180
      },
      {
        id: "4",
        name: "Chicken Korma",
        details:
          "White &amp; Creamy gravy made of cashewnut and yogurt mixed with chicken ; you definitely want this on your menu",
        image: "https://madchefcateringservices.com/imgur-images/PC6HUGu.jpg",
        price:200
      },
      {
        id: "5",
        name: "Peshwari Chicken Korai",
        details:
          "Taste chicken like the Peshwaris, rich in butter and garnished with fresh cilantro. ",
        image: "https://madchefcateringservices.com/imgur-images/keluelR.jpg",
        price:200
      },
      {
        id: "6",
        name: "Boneless Chicken Handi",
        details:
          "Your clay pot of boneless chicken dunked in rich creamy gravy. ",
        image: "https://madchefcateringservices.com/imgur-images/wct4Uvb.jpg",
        price:215
      },
      {
        id: "7",
        name: "Deshi Chicken & Keema Curry ",
        details:
          "Finely minced chicken with golden onion and green chili curry that will carry you away!",
        image: "https://madchefcateringservices.com/imgur-images/cPIsaf1.jpg",
        price:285
      },
      {
        id: "8",
        name: "Chicken Aloo Roshun Jhaal Jhol",
        details:
          "The big roasted potatoes that comes with your chicken in a pool of spicy gravy.",
        image: "https://madchefcateringservices.com/imgur-images/3EYAwRI.jpg",
        price:175
      },
      {
        id: "9",
        name: "Chicken Gila-kolija Bhuna",
        details: "Scrumptious chicken liver in all spices!",
        image: "https://madchefcateringservices.com/imgur-images/M75YUgk.jpg",
        price:130
      }
    ];
    return (
      <Row>
        {foods.map(food => (
          <Col md="6" lg="4" sm="6" xs="12" key={food.name}>
            <FoodItem
              currentMenu={this.props.currentMenu}
              onAddItem={this.props.onAddItem}
              key={food.id}
              id={food.id}
              food={food}
            />
          </Col>
        ))}
      </Row>
    );
  }
}

export default Chicken;
