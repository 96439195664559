import React, { Component } from "react";
import Card from "react-bootstrap/Card";

class FoodCard extends Component {
  state = {};
  render() {
    return (
      <Card className="card-style">
        <Card.Img variant="top" src={this.props.image} />
        <Card.Text className="card-background">
          <h5 style={{ color: "#F69D36" }}>{this.props.title}</h5>
        </Card.Text>
      </Card>
    );
  }
}

export default FoodCard;
