import React, { Component } from "react";
import FoodCardCarosel from "./FoodCardCarosel";
class Recomendation extends Component {
  state = {};
  render() {
    return (
      <section class="bgimage-recomendation">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h1>Recommended By Our Madchef</h1>
              <p>
                Food tastes better when you eat together. Our menu consists of
                items which are enjoyed best when shared. We source the freshest
                local and sustainable ingredients from the market and cook to
                order.
              </p>
            </div>
          </div>
          <div
            style={{ marginTop: "4%", marginLeft: "15%", marginRight: "10%" }}
          >
            <FoodCardCarosel />
          </div>
        </div>
      </section>
    );
  }
}

export default Recomendation;
