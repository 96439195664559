import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import FoodItem from "../CreateMenu/FoodItem";

class Fish extends Component {
  state = {};
  render() {
    var foods = [
      {
        id: "1",
        name: "Golda Chingri Malai Curry",
        details:
          "A dinner-table winner of big prawns in a sauce, oh so creamy!",
        image: "https://madchefcateringservices.com/imgur-images/3ONBYmm.jpg",
        price:280
      },
      {
        id: "2",
        name: "Crumb Fried Pomfret",
        details: "Fried well with a coat of crumbs, this pomfret is yum!",
        image: "https://madchefcateringservices.com/imgur-images/dYddqxr.jpg",
        price:440
      },
      {
        id: "3",
        name: "Tawa fried Pomfret",
        details:
          "Tender on the inside, crunchy on the outside is how this roasted pompfret rolls!",
        image: "https://madchefcateringservices.com/imgur-images/eSQIILE.jpg",
        price:440
      },
      {
        id: "4",
        name: "Shorshe iilish",
        details: "A ‘Bengali must’ of a mustardy Hilsha that will blow you away.",
        image: "https://madchefcateringservices.com/imgur-images/szgdDmG.jpg",
        price:250
      },
      {
        id: "5",
        name: "Rui Maacher Kaliya",
        details: "Roached fish in a masala rich gravy.",
        image: "https://madchefcateringservices.com/imgur-images/u2irnA2.jpg",
        price:200
      },
      {
        id: "6",
        name: "Rui maach Bhaja",
        details: "Fried rui garnished with golden and, crispy onion.",
        image: "https://madchefcateringservices.com/imgur-images/HOBWrzS.jpg",
        price:130
      },
      {
        id: "7",
        name: "Rui Kofta curry",
        details: "Deep fried fish balls in a gourmet thick curry.",
        image: "https://madchefcateringservices.com/imgur-images/eIVbYew.jpg",
        price:220
      },
      {
        id: "8",
        name: "Amritsari Fish Fry",
        details:
          "Street food starter in your restaurant plate – spicy and flavorful fish!",
        image: "https://madchefcateringservices.com/imgur-images/pEvlavr.jpg",
        price:230
      }
    ];
    return (
      <Row>
        {foods.map(food => (
          <Col md="6" lg="4" sm="6" xs="12" key={food.name}>
            <FoodItem
              currentMenu={this.props.currentMenu}
              onAddItem={this.props.onAddItem}
              key={food.id}
              id={food.id}
              food={food}
            />
          </Col>
        ))}
      </Row>
    );
  }
}

export default Fish;
