import React, { Component } from "react";
import Header from "./Master/MenuBar";
import Call from "./Master/Call";
import OrangeBar from "./Master/OrangeBar";
import Container from "react-bootstrap/Container";
import BBQCard from "./BBQMenu/BBQCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class BBQMenu extends Component {
  state = { scrolled: false };

  componentDidMount() {
    let that = this;
    window.scrollTo(0, 0);
    window.onscroll = function () {
      if (window.pageYOffset > 0) {
        that.setState({ scrolled: true });
      } else {
        that.setState({ scrolled: false });
      }
    };
  }

  render() {
    const meat = [
      { name: "Beef Sheekh Kabab", price: "TK. 210" },
      { name: "Turkish Adana Kabab", price: "TK. 210" },
      { name: "Mutton Boti Kabab ", price: "TK. 220" },
      { name: "Khiri Kabab", price: "TK. 200" },
      { name: "Gurda Kolija Bhuna", price: "TK. 250" },
    ];

    const chicken = [
      { name: "Grilled Tandoori Chicken (Qtr) ", price: "TK. 140" },
      { name: "Boneless Chicken Malai Tikka ", price: "TK. 140" },
      { name: "Chicken Bihari Boti ", price: "TK. 160" },
      { name: "Bone-in Chicken Chaap", price: "TK. 160" },
    ];

    const fish = [
      { name: "Grilled Masala Pomfret", price: "Depends on size " },
      { name: "Koral BBQ", price: "TK. 1450/KG" },
      { name: "Tandoori Prawns", price: "TK. 1500/KG" },
      { name: "Spicy Cube Fish Tikka", price: "TK. 1300/KG" },
    ];

    const sides1 = [
      { name: "Peas Polao", price: "TK. 80" },
      { name: "Paratha/ Bosnian Bread", price: "TK. 30" },
      { name: "Mixed Deshi Veggies", price: "TK. 80" },
      { name: "Shahi Mattar Paneer", price: "TK. 90" },
    ];

    const sides2 = [
      { name: "Butter Daal (Gila Koliia Optional)", price: "TK. 90" },
      { name: "Aloo Tomato Gravy", price: "TK. 80" },
      { name: "Raita", price: "TK. 60" },
      { name: "Mint Pudina Chutney", price: "TK. 60" },
    ];

    const b_meat = [
      { name: "Premium Grade Ribeye Steak (250gm)", price: "TK. 990" },
      { name: "Mutton Rib Chops", price: "TK. 690" },
    ];

    const b_chicken = [
      { name: "Grilled Peri-Peri Chicken", price: "TK. 280" },
      { name: "Grilled Greek Chicken", price: "TK. 190" },
      { name: "Grilled Gyro Chicken (Qtr)", price: "TK. 180" },
    ];

    const b_fish = [
      { name: "Garlic Butter Prawns on Shell", price: "Depends on size" },
      { name: "Grilled Cream Dori", price: "TK. 1550/KG" },
      { name: "Grilled Cajun Snapper", price: "TK. 1550/KG" },
    ];

    const b_sides = [
      { name: "Egg Fried Rice", price: "TK. 90" },
      { name: "Grilled Seasonal Veggies", price: "TK. 90" },
      { name: "Grilled Garlic Mushrooms", price: "TK. 180" },
      { name: "Mashed Potatoes", price: "TK. 140" },
      { name: "Fries/Wedges", price: "TK. 150" },
    ];

    return (
      <div>
        <Header scrolled={this.state.scrolled} />
        <Call />
        <OrangeBar pageName="BBQ Menu" />
        <Container className="corporate-bg"></Container>

        <Container style={{ marginTop: "2rem", marginBottom: "1rem" }}>
          <h1
            style={{ color: "#471435", margin:"1rem 0 2rem 0", fontSize: "3.5rem", textAlign: "center" }}
          >
            DESHI
          </h1>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <BBQCard
                title="MEAT"
                foods={meat}
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <BBQCard
                title="CHICKEN"
                foods={chicken}
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <BBQCard
                title="FISH"
                foods={fish}
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <BBQCard
                title="SIDES-1"
                foods={sides1}
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <BBQCard
                title="SIDES-2"
                foods={sides2}
              />
            </Col>

          </Row>
        </Container>

        <Container style={{ marginTop: "2rem", marginBottom: "1rem" }}>
          <h1
            style={{ color: "#471435", margin:"1rem 0 2rem 0", fontSize: "3.5rem", textAlign: "center" }}
          >
            BIDESHI
          </h1>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <BBQCard
                title="MEAT"
                foods={b_meat}
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <BBQCard
                title="CHICKEN"
                foods={b_chicken}
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <BBQCard
                title="FISH"
                foods={b_fish}
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <BBQCard
                title="SIDES"
                foods={b_sides}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default BBQMenu;
