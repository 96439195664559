import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
class MCSCard extends Component {
  state = {};
  render() {
    return (
      <Card className="mcs-card-background">
        <Card.Text
          style={{
            textAlign: "center",
            padding: "0rem 1rem 0rem 1rem"
          }}
        >
          <Row style={{ margin: "0px" }}>
            <Col class="img-col" md={4} >
              <img className="mcs-img"
                src={this.props.img}
                alt="mcs"
              />
            </Col>
            <Col md={8}>
              <div style={{ margin: "0.5rem 0rem 0rem 0rem" }}>
                <h4 style={{ color: "#F69D36", marginTop: ".1rem" }}>
                  {this.props.title}
                </h4>
                <h6 style={{ color: "#F69D36", marginTop: ".1rem" }}>
                  {this.props.subTitle}
                </h6>
                <p style={{ color: "#E5E5E5", fontSize: "1rem" }}>
                  {this.props.details}
                </p>
              </div>
            </Col>
          </Row>
        </Card.Text>
      </Card>
    );
  }
}

export default MCSCard;
