import React, { Component } from 'react';
import Card from "react-bootstrap/Card";

class Packagecard extends Component {
  state = {}
  render() {
    return (
      <Card className="mcs-card-background">
        <Card.Text
          style={{
            textAlign: "center",
            padding: "0rem 1rem 0rem 1rem"
          }}
        >
          <div style={{ margin: "1rem 0rem 1rem 0rem", height:"10rem" }}>
            <h4 style={{ color: "#F69D36", marginTop: ".1rem" }}>
              {this.props.title}
            </h4>
            <div style={{ color: "#E5E5E5", fontSize: "1rem" }}>
              {this.props.food1}
            </div>
            <div style={{ color: "#E5E5E5", fontSize: "1rem" }}>
              {this.props.food2}
            </div>
            <div style={{ color: "#E5E5E5", fontSize: "1rem" }}>
              {this.props.food3}
            </div>
            <div style={{ color: "#E5E5E5", fontSize: "1rem" }}>
              {this.props.food4}
            </div>
            <div style={{ color: "#E5E5E5", fontSize: "1rem" }}>
              {this.props.food5}
            </div>
          </div>
        </Card.Text>
      </Card>
    );
  }
}

export default Packagecard;