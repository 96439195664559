import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import OrangeBar from "./Master/OrangeBar";
import GetInTouch from "./Contact/GetInTouch";
import ContactInfo from "./Contact/ContactInfo";
import Header from "./Master/MenuBar";
import Call from "./Master/Call";
class ContactUs extends Component {
  state = { scrolled: false };

  componentDidMount() {
    let that = this;
    window.scrollTo(0, 0);
    window.onscroll = function () {
      if (window.pageYOffset > 0) {
        that.setState({ scrolled: true });
      }
      else {
        that.setState({ scrolled: false });
      }
    };
  };

  render() {
    return (
      <div>
        <Header scrolled={this.state.scrolled} />
        <Call />
        <OrangeBar pageName="Contact Us" />
        <Container>
          <div>
            <Row>
              <Col md="6" lg="6">
                <GetInTouch />
              </Col>
              <Col md="1" lg="1"></Col>
              <Col md="5" lg="5">
                <ContactInfo />

              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

export default ContactUs;
