import React, { Component } from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";
import axios from "axios";

class CorporateForm extends Component {
  state = {
    fromPage: "corporate",
    name: "",
    mail: "",
    phone: "",
    company: "",
    designation: "",
    comment: "",
    show: false
  };

  handleName = event => {
    this.setState({ name: event.target.value });
  };
  handleEmail = event => {
    this.setState({ mail: event.target.value });
  };
  handleComment = event => {
    this.setState({ comment: event.target.value });
  };
  handlePhone = event => {
    this.setState({ phone: event.target.value });
  };
  handleCompany = event => {
    this.setState({ company: event.target.value });
  };
  handleDesignation = event => {
    this.setState({ designation: event.target.value });
  };
  handleClose = () => {
    this.setState({ show: false });
    window.location.reload();
  };
  handleSubmit = event => {
    event.preventDefault();
    axios
      .post("https://madchefcateringservices.com/api/main.js", this.state)
      .then(res => {
        //console.log(res);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
    this.setState({ show: true });
  };

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        style={{ paddingTop: "20px", paddingBottom: "30px" }}
      >
        <Form.Row>
          <Form.Group as={Col} md="6">
            <Form.Control
              type="text"
              style={{ border: "1.5px solid #471435" }}
              placeholder="Name *"
              onChange={this.handleName}
              required
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Control
              type="number"
              style={{ border: "1.5px solid #471435" }}
              placeholder="Phone Number * "
              onChange={this.handlePhone}
              required
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6">
            <Form.Control
              style={{ border: "1.5px solid #471435" }}
              type="text"
              placeholder="Company *"
              onChange={this.handleCompany}
              required
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Control
              type="text"
              style={{ border: "1.5px solid #471435" }}
              placeholder="Designation "
              onChange={this.handleDesignation}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="6">
            <Form.Control
              style={{ border: "1.5px solid #471435" }}
              type="email"
              placeholder="E-mail Address *"
              onChange={this.handleEmail}
              required
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Control
              style={{ border: "1.5px solid #471435" }}
              as="textarea"
              rows="1"
              placeholder="Comment "
              onChange={this.handleComment}
            />
          </Form.Group>
        </Form.Row>

        <Button className="corporate-button" type="submit">
          Submit
        </Button>
        <Modal
          style={{ top: "300px", border: "0" }}
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton style={{ backgroundColor: "#f69d36" }}>
            <Modal.Title style={{ color: "#471435" }}>
              Congratulations !! You've Succesfully Placed a Request. We will
              get back to you soon.
            </Modal.Title>
          </Modal.Header>
        </Modal>
      </Form>
    );
  }
}

export default CorporateForm;
