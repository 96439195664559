import React, { Component } from "react";
class COntactInfo extends Component {
  state = {};
  render() {
    return (
      <div
        style={{
          paddingTop: "1.5rem",
          color: "#471435"
        }}
      >
        <h5 className="footer-header">Contact Us</h5>
        <li className="contact-item">
          <li class=" fa fas fa-phone-square" />
          {"  "}+880-1856-585694
        </li>
        <li className="contact-item">
          <li class=" fa far fa-envelope" />
          {"  "}contact@madchefcateringsevices.com
        </li>

        <li className="contact-item">
          <li class=" fa fa-map-marker" />
          {"  "}1st Floor, Hasan Dorji Super Market, Momtajuddin Kacha Bazar
          Road, Solmaid, Dhaka{" "}
        </li>
        <div
          className="footer-social"
          style={{ marginLeft: "-5px", fontSize: "2rem" }}
        >
          <a
            style={{ color: "#471435" }}
            href="https://fb.com/madchefcateringservices"
            target="_blank"
            rel="noreferrer noopener"
            className="fa fab fa-facebook-square icons"
          >
            {}
          </a>

          <a
            style={{ color: "#471435" }}
            href="https://fb.com/madchefcateringservices"
            target="_blank"
            rel="noreferrer noopener"
            className="fa fab fa-instagram icons"
          >
            {}
          </a>
          <a
            style={{ color: "#471435", marginLeft: "5px" }}
            href="https://m.me/madchefcateringservices"
            target="_blank"
            rel="noreferrer noopener"
            className="fa fas fa-comments"
          >
            {}
          </a>
        </div>
      </div>
    );
  }
}

export default COntactInfo;
