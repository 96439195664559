import React, { Component } from "react";
//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class Call extends Component {
  state = { display: false };

  handleShow = () => {
    this.setState({ display: !this.state.display });
  };
  render() {
    return (
      <div>
        <Button onClick={this.handleShow}>
          <img
            className="call"
            alt="call"
            src="https://madchefcateringservices.com/imgur-images/SUAa5nH.png"
          />
        </Button>
        {this.state.display ? (
          <div className="phone-number">
            <a href="tel:01856585694">
              <h4>01856585694</h4>
            </a>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Call;
