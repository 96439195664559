import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import OrangeBar from "./Master/OrangeBar";
import MenuTabs from "./CreateMenu/MenuTabs";
import MenuForm from "./CreateMenu/MenuForm";
import Header from "./Master/MenuBar";
import Call from "./Master/Call";
import Cart from "./CreateMenu/Cart"

class CreateMenu extends Component {
  state = {
    scrolled: false,
    menu: [],
    totalPrice: 0,
    menuString: "",
  };

  componentDidMount() {
    let that = this;
    window.scrollTo(0, 0);
    window.onscroll = function () {
      if (window.pageYOffset > 0) {
        that.setState({ scrolled: true });
      }
      else {
        that.setState({ scrolled: false });
      }
    };
  }

  handleAddItem = item => {
    const tempMenu = [...this.state.menu];
    tempMenu.push(item);
    let tempString=this.state.menuString;

    if(tempString=== ""){
      tempString = item.name;
    }
    else{
      tempString = tempString + ", "+item.name
    }

    this.setState({
      menu: tempMenu,
      totalPrice: this.state.totalPrice + item.price,
      menuString: tempString

    });
  };

  handleCancelClick = index => {
    this.setState({
      totalPrice: this.state.totalPrice - this.state.menu[index].price,
    });

    const tempMenu = [...this.state.menu];
    tempMenu.splice(index, 1);
    this.setState({
      menu: tempMenu,
    });
  };

  scrollToBottom = () => {
    window.scrollTo(0, 5000);
  }


  render() {
    //console.log(this.state.menu);
    return (
      <div>
        <Header scrolled={this.state.scrolled} />
        <Call />
        <Cart onCheckout={this.scrollToBottom} menu={this.state.menu} totalPrice={this.state.totalPrice} handleCancelClick={this.handleCancelClick} />
        <OrangeBar pageName=" Catering Menus with a MAD Touch" />
        <Container className="text-section">
          <img style={{padding:"1rem 0rem"}}src="https://madchefcateringservices.com/imgur-images/fjdI7Ra.png" alt="howTo" />
        </Container>

        <MenuTabs
          currentMenu={this.state.menu}
          onAddItem={this.handleAddItem}
        />
        <MenuForm
          menu={this.state.menu}
          onCancelClick={this.handleCancelClick}
          totalPrice={this.state.totalPrice}
          menuString={this.state.menuString}
        />
      </div>
    );
  }
}

export default CreateMenu;
