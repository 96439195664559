import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import FoodItem from "../CreateMenu/FoodItem";

class Mutton extends Component {
  state = {};
  render() {
    var foods = [
      {
        id: "1",
        name: "Mutton Roganjosh",
        details:
          "Slowly cooked in aromatic spices, this is your cold days comfort dish of juicy mutton!",
        image: "https://madchefcateringservices.com/imgur-images/aQGJzse.jpg",
        price:430
      },
      {
        id: "2",
        name: "Rara Mutton and Keema Curry",
        details:
          "We have got red Kashmiri chili to spice up your mutton bowl of food-wish!",
        image: "https://madchefcateringservices.com/imgur-images/F1KfDbc.jpg",
        price:480
      },
      {
        id: "3",
        name: "Shahi Mutton Ghee Roast",
        details: "Chunks of mutton roasted in ghee, how can you not want this?",
        image: "https://madchefcateringservices.com/imgur-images/Cdu9mHZ.jpg",
        price:320
      }
    ];
    return (
      <Row>
        {foods.map(food => (
          <Col md="6" lg="4" sm="6" xs="12" key={food.name}>
            <FoodItem
              currentMenu={this.props.currentMenu}
              onAddItem={this.props.onAddItem}
              key={food.id}
              id={food.id}
              food={food}
            />
          </Col>
        ))}
      </Row>
    );
  }
}

export default Mutton;
