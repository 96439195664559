import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

class CardDemo extends Component {
  state = {};
  render() {
    //console.log(this.props.imgSrc);
    return (
      <Card className="customer-card">
        <Image
          className="customer-img"
          variant="top"
          src={this.props.imgSrc}
          roundedCircle
        />
        <Card.Body>
          <Card.Text className="customer-quote">{this.props.quote}</Card.Text>
        </Card.Body>
        <Card.Text className="customer-name">
          <i
            className="fa far fa-star"
            style={{ marginRight: ".5rem", color: "#471435" }}
          ></i>
          <i
            className="fa far fa-star"
            style={{ marginRight: ".5rem", color: "#471435" }}
          ></i>
          <i
            className="fa far fa-star"
            style={{ marginRight: ".5rem", color: "#471435" }}
          ></i>
          <i
            className="fa far fa-star"
            style={{ marginRight: ".5rem", color: "#471435" }}
          ></i>
          <i className="fa far fa-star" style={{ color: "#471435" }}></i>
        </Card.Text>
        <hr className="customer-hr" />
        <Card.Text style={{ textAlign: "center", marginTop: "-10px" }}>
          {this.props.name}
        </Card.Text>
        <Card.Text style={{ textAlign: "center", marginTop: "-20px",fontSize:"0.9rem" }}>
          {this.props.designation}
        </Card.Text>
        <Card.Text style={{ textAlign: "center", marginTop: "-10px",fontSize:"0.9rem" }}>
          {this.props.company}
        </Card.Text>
      </Card>
    );
  }
}

export default CardDemo;
