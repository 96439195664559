import React, { Component } from "react";
import Container from "react-bootstrap/Container";
class OrangeBar extends Component {
  state = {};
  render() {
    return (
      <Container fluid={"true"} className="orange-bar">
        <h1>{this.props.pageName}</h1>
      </Container>
    );
  }
}

export default OrangeBar;
