import React, { Component } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PackageCard from "./Packages/packageCard"
import OrangeBar from "./Master/OrangeBar";
import Header from "./Master/MenuBar";
import Call from "./Master/Call";

class Packages extends Component {
  state = { scrolled: false };

  componentDidMount() {
    let that = this;
    window.scrollTo(0, 0);
    window.onscroll = function () {
      if (window.pageYOffset > 0) {
        that.setState({ scrolled: true });
      }
      else {
        that.setState({ scrolled: false });
      }
    };
  };

  render() {
    return (
      <div>
        <Header scrolled={this.state.scrolled} />
        <Call />
        <OrangeBar pageName=" The 360° satisfaction" />
        <Container style={{ marginTop: "2rem", marginBottom:"1rem" }}>
          <h1 style={{color:"#471435", fontSize:"3rem", textAlign:"center"}}>Bangla</h1>
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <PackageCard
                title="Package 1 (220tk)"
                food1="Plain Peas Polao"
                food2="Biye Bari Chicken Roast"
                food3="Egg Masala"
                food4=""
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <PackageCard
                title="Package 2 (290tk)"
                food1="Plain Rice"
                food2="Beef Kala Bhuna/ Chicken Bhuna"
                food3="Egg Chop"
                food4="Ghono Dal"
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <PackageCard
                title="Package 3 (280tk)"
                food1="Plain Rice"
                food2="Rui Mach Kaliya"
                food3="Ghono Dal"
                food4="Mixed Deshi Vegetables"
              />
            </Col>
          </Row>

          <h1 style={{color:"#471435", fontSize:"3rem",textAlign:"center"}}>Oriental</h1>
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <PackageCard
                title="Package 1 (270tk)"
                food1="Chicken/Prawn Fried Rice"
                food2="Bangkok Fried Chicken"
                food3="Beef Chili Onion"
                food4="Mixed Chinese Vegetables"
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <PackageCard
                title="Package 2 (290tk)"
                food1="Egg Fried Rice"
                food2="Chicken Chili Onion"
                food3="Sweet &amp; Sour Prawn"
                food4="Mixed Chinese Vegetables"
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <PackageCard
                title="Package 3 (190tk)"
                food1="Chicken Chow Mein"
                food2="Bangkok Fried Chicken"
                food3="Mixed Thai Vegetables"
                food4=""
              />
            </Col>
          </Row>

          <h1 style={{color:"#471435", fontSize:"3rem",textAlign:"center"}}>Box Packages</h1>
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <PackageCard
                title="Package 1 (200tk)"
                food1="Egg Fried Rice"
                food2="Chicken/Beef Chili Onion"
                food3="Mixed Chinese Vegetables"
                food4=""
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <PackageCard
                title="Package 2 (200tk)"
                food1="Chow Mein"
                food2="Fried Chicken"
                food3="Mixed Chinese Vegetables"
                food4=""
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <PackageCard
                title="Package 3 (280tk)"
                food1="Chicken/Prawn Fried Rice"
                food2="Bangkok Fried Chicken"
                food3="Prawn Chili Onion"
                food4=""
              />
            </Col>
          </Row>

          <h1 style={{color:"#471435", fontSize:"3rem",textAlign:"center"}}>Deluxe Packages</h1>
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <PackageCard
                title="Package 1 (450tk)"
                food1="Plain Peas Polao/Paratha"
                food2="Biye Bari Chicken Roast"
                food3="Rui Kofta Curry"
                food4="Egg Masala"
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <PackageCard
                title="Package 2 (700tk)"
                food1="Plain Peas Polao"
                food2="Rui Mach Kaliya"
                food3="Chicken Rezala"
                food4="Mezban Beef"
                food5="Mixed Deshi Vegetables"
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <PackageCard
                title="Package 3 (850tk)"
                food1="Plain Peas Polao"
                food2="Beef Kala Bhuna"
                food3="Achari Chicken"
                food4="Tawa fried Pomfret"
                food5="Shahi Mattar Paneer"
              />
            </Col>
          </Row>
        
        </Container>
      </div>
    );
  }
}

export default Packages;