import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
//import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
//import Container from "react-bootstrap/Container";

class ContactUsSection extends Component {
  state = {};
  render() {
    return (
      <Container fluid={true}>
        <Row className="contact-us-section">
          <Col xl={1} lg={1} md={1}></Col>
          <Col xl={8} lg={8} md={8}>
            For over 5 years, Dhaka has looked towards Madchef to create truly
            delicious food. We invite you to put the experience to the test, and
            taste. Madly satisfying results await you.
          </Col>
          <Col style={{ marginTop: "1.1rem" }}>
            <NavLink className="contact-button" to={"/contact"}>
              Contact Us
            </NavLink>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ContactUsSection;
