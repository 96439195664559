import React, { Component } from "react";
import Card from "react-bootstrap/Card";

class BBQCard extends Component {
  state = {};
  render() {
    return (
      <Card className="mcs-card-background">
        <Card.Text
          style={{
            textAlign: "left",
            padding: "0rem 1rem 0rem 1rem",
          }}
        >
          <div style={{ margin: "1rem 0rem 1rem 0rem", height: "12.25rem" }}>
            <h4 style={{ color: "#F69D36", marginTop: ".1rem" }}>
              {this.props.title}
            </h4>

            {this.props.foods.map((food) => (
              <div className="bbq-card">
                <div className="bbq-card-text">{food.name}</div>
                <div className="bbq-card-text">{food.price}</div>
              </div>
            ))}
          </div>
        </Card.Text>
      </Card>
    );
  }
}

export default BBQCard;
