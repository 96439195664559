import React, { Component } from "react";
import CustomerCardGrid from "./Home/CustomerCardGrid";
import ContactUsSection from "./Home/ContactUsSection";
import Hero from "./Home/Hero";
import WhatIsMadchef from "./Home/WhatIsMadchef";
import Recomendation from "./Home/Recomendation";
import Header from "./Master/MenuBar";
import Call from "./Master/Call";
class Home extends Component {
  state = { scrolled: false };
  
  componentDidMount() {
    let that=this;
    window.scrollTo(0, 0);
    window.onscroll = function() {
      if(window.pageYOffset > 0) {
        that.setState({scrolled: true});
      }
      else{
        that.setState({scrolled: false});
      }
    };
  };


  render() {
    console.log(this.state);
    return (
      <div>
        <Header scrolled={this.state.scrolled} />
        <Call />
        <Hero />
        <WhatIsMadchef />
        <Recomendation />
        <CustomerCardGrid />
        <ContactUsSection />
      </div>
    );
  }
}

export default Home;
