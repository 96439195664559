import React, { Component } from "react";
import Card from "react-bootstrap/Card";
class FoodItem extends Component {
  state = {
    buttonShow: true
  };

  componentDidMount() {
    let flag = 0;
    this.props.currentMenu.map(item => {
      if (item.name === this.props.food.name) {
        flag = 1;
      }
      return flag;
    })
    flag ? this.setState({ buttonShow: false }) : this.setState({ buttonShow: true });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentMenu !== this.props.currentMenu) {
      let flag = 0;
      nextProps.currentMenu.map(item => {
        if (item.name === this.props.food.name) {
          flag = 1;
        }
        return flag;
      })
      flag ? this.setState({ buttonShow: false }) : this.setState({ buttonShow: true });
    }
  }


  render() {
    return (
      <Card className="food-item">
        <Card.Img
          className="food-item-img"
          variant="top"
          src={this.props.food.image}
        />

        <div className="card-background">
          <h5 style={{ color: "#F69D36", margin: "0" }}>
            {this.props.food.name}
          </h5>
          <p
            style={{ color: "#E5E5E5", fontSize: "1rem", marginBottom: "5px", height: "4.5rem" }}
          >
            {this.props.food.details}
          </p>


          <div className="food-item-row">
            <div className="food-price">
              <h6>
                &#2547; {this.props.food.price}
              </h6>
            </div>

            {this.state.buttonShow ? (
              <button
                className="yellow-button"
                onClick={() => this.props.onAddItem(this.props.food)}
              >
                Add To Menu
            </button>
            ) : (
                <img
                  style={{ width: "2rem" }}
                  src="https://madchefcateringservices.com/imgur-images/bRrwIu8.png"
                  alt="tik"
                />
              )}
          </div>

        </div>
      </Card>
    );
  }
}

export default FoodItem;
